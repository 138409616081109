import React from 'react'
import Layout from 'containers/Layout'
import Story from 'storybook/Story'
import { graphql } from 'gatsby'
import Meta from 'theme/atoms/Meta'
import { useQuery, useProductList } from 'modules/products'
import { ms } from 'modules/browser/const'
import styled from 'styled-components'

type Props = {
  location: {
    pathname: string
    key: string
  }
  data: {
    staticBlock: {
      story: any
    }
  }
}

export default (props: Props) => {
  const { story } = props.data.staticBlock

  const crumbs = [{ label: 'Startseite', link: '/' }, { label: 'Suche' }]

  return (
    <Layout partialStateUpdates={story.partialStateUpdates} breadcrumbs={crumbs}>
      <Query pathname={props.location.pathname} />
      <Story key={props.location.key} story={story} />
    </Layout>
  )
}

function Query({ pathname }: any) {
  const { query } = useQuery('generic-product-list')
  const title = query
    ? `Ergebnisse für "${query}"`
    : `Damenmode in großen Größen im Wundercurves-Shop`
  return (
    <>
      <Meta
        type="website"
        pathname={pathname}
        title={`Finde ${query || ''} Mode in großen Größen online bei Wundercurves`}
        description={`Finde ${query || ''} Mode in großen Größen online bei Wundercurves`}
      />
      <Title>
        <h2>{title}</h2>
        <NumHits />
      </Title>
    </>
  )
}

function NumHits() {
  const products = useProductList('generic-product-list')
  return <div className="hits">{products.numHits} ERGEBNISSE</div>
}

const Title = styled.div`
  color: #262626;
  text-align: center;
  @media (min-width: ${ms.LAPTOP_XL}px) {
    margin: 0 25px;
  }
  > h2 {
    font-family: 'Cormorant Garamond';
    color: #262626;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 36px;
    padding: 25px 20px;
    margin: 0;
    padding-bottom: 0px !important;

    @media (min-width: ${ms.LAPTOP}px) {
      font-size: 36px;
      letter-spacing: 0.64px;
      line-height: 42px;
      padding: 60px 0;
    }
  }

  > .hits {
    margin-top: 8px;
    color: #262626;
    font-family: Raleway;
    font-size: 11px;
    letter-spacing: 2.4px;
    line-height: 20px;
    height: 25px;
    margin-bottom: -30px !important;
    @media (min-width: ${ms.LAPTOP}px) {
      height: 40px;
    }
  }
`

export const query = graphql`
  query {
    staticBlock(identifier: { eq: "Search_Static_Block" }) {
      story
    }
  }
`
